import React, {FunctionComponent} from "react";
import styled from "styled-components";
import {Container, AvatarImage} from "../common";
import Theme from "../../styles/theme";

interface SubheaderProps {
  title: string;
  subtitle?: string;
  backgroundColor?: string;
  imageUrl?: string;
  textColor?: string;
}

const StyledSubheader = styled.div<Pick<SubheaderProps, 'backgroundColor' | 'textColor'>>`
  background-color: ${props => props.backgroundColor ? props.backgroundColor : '#000'};
  color: ${props => props.textColor ? props.textColor : '#fff'};
  display: flex;
  align-items: center;
  height: 90px;
  max-width: 1000px;
  margin: auto;
  margin-bottom: 20px;
  padding: 20px;
`;

const SubheaderTitle = styled.h1`
  font-size: 1.2em;
  font-weight: bold;
  color: #fff;
  margin: 0;
  line-height: 1em;
`;

const SubheaderSubtitle = styled.small`
  font-weight: normal;
  display: block;
  opacity: .9;
`;

const HorizontalContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Subheader: FunctionComponent<SubheaderProps> = (props) => (
  <StyledSubheader backgroundColor={props.backgroundColor} textColor={props.textColor}>
    <HorizontalContainer>
      {props.imageUrl && <AvatarImage src={props.imageUrl} />}
      <SubheaderTitle>
        {props.title}
        <SubheaderSubtitle>{props.subtitle}</SubheaderSubtitle>
      </SubheaderTitle>
    </HorizontalContainer>
  </StyledSubheader>
);

export default Subheader;
